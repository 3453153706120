import { useEffect } from "react";
import css from "./popUp.module.css";

export default function PopCH({ popupPosition, scrollHeight }) {
  return (
    <div className={css.popCHWrap}>
      <p className={css.mainPopP}>
        Члени клуба - це всі зареєстровані користувачі, що підписані на
        розсилку. Видавництву важливо мати прямий контакт з читачами. Член клубу
        має знижку 5% і доступ до роздачі Слонів (1 слон = 1 грн). За рахунок
        Слонів можна зменшити суму корзини. Читачі можуть приймати участь у
        популяризації книги. Ви пишете відгук (мінімум 350 знаків про нашу
        книгу) і на ваш рахунок зараховуються Слони в сумі відповідно до об'єму
        книги. Приймаєте участь в Опікунській раді - нараховуються Слони і
        надаються знижки 40% на опікуванні книги. Допомагайте у створенні книги.
        Вам безоплатно надаються доступ до прочитання рукописів (відкриває
        сторінку сайта Рукописи), ще до видання книги. Ваш відгук на рукопис
        допомагає видавництву обрати саме той рукопис,
      </p>
    </div>
  );
}
