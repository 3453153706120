// import HeaderApp from "./headerApp";
// import "./header.css";
// import HeaderDown from "./headerDown";
// import { useState } from "react";
// import FilterMenu from "./filterMenu";
// import LogIn from "../logIn/logIn";
// import EnterUser from "../enterUser/enterUser";
// import Card from "../card/card";
// import FilterMenuManu from "./filterMenuManu";
// import { useLocation } from "react-router-dom";
// import { useEffect } from "react";

// export default function Header({
//   setLogin,
//   login,
//   setEnterUser,
//   enterUser,
//   scrollHeight,
//   cartCounterC,
//   totalQuantity,
//   setCartCounterC,
//   likedCount,
// }) {
//   const [allBooks, setAllBooks] = useState(false);
//   const [windowDimensions, setWindowDimensions] = useState(true);
//   const [allManus, setAllManus] = useState(false);
//   const [cart, setCart] = useState(false);
//   const [countProductForCart, setCountProductForCart] = useState();
//   const location = useLocation();
//   const [isHeaderVisible, setIsHeaderVisible] = useState(true);
//   const [lastScrollTop, setLastScrollTop] = useState(0);
//   useEffect(() => {
//     const handleScroll = () => {
//       const currentScrollTop =
//         window.pageYOffset || document.documentElement.scrollTop;

//       if (currentScrollTop > lastScrollTop + 10 && currentScrollTop > 180) {
//         // Прокрутка вниз на більше ніж 10px і пройдено більше 180px
//         setIsHeaderVisible(false);
//       } else if (currentScrollTop < lastScrollTop - 10) {
//         // Прокрутка вгору на більше ніж 10px
//         setIsHeaderVisible(true);
//       }

//       setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [lastScrollTop]);
//   useEffect(() => {
//     function handleResize() {
//       if (window.innerWidth < 1100) {
//         setWindowDimensions(false);
//       } else {
//         setWindowDimensions(true);
//       }
//     }
//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location, allBooks]);
//   const contentStyle = {
//     paddingTop: isHeaderVisible ? "180.33px" : "0",
//   };
//   return (
//     <>
//       <HeaderApp
//         windowDimensions={windowDimensions}
//         setWindowDimensions={setWindowDimensions}
//         countProductForCart={countProductForCart}
//         setLogin={setLogin}
//         login={login}
//         setCart={setCart}
//         cartCounterC={cartCounterC}
//         totalQuantity={totalQuantity}
//         likedCount={likedCount}
//       />
//       <HeaderDown
//         windowDimensions={windowDimensions}
//         setWindowDimensions={setWindowDimensions}
//         setAllBooks={setAllBooks}
//         allManus={allManus}
//         setAllManus={setAllManus}
//         allBooks={allBooks}
//       />
//       {allBooks && (
//         <FilterMenu
//           allBooks={allBooks}
//           setAllBooks={setAllBooks}
//           windowDimensions={windowDimensions}
//           setWindowDimensions={setWindowDimensions}
//         />
//       )}
//       {allManus && (
//         <FilterMenuManu
//           setAllManus={setAllManus}
//           windowDimensions={windowDimensions}
//           setWindowDimensions={setWindowDimensions}
//         />
//       )}
//       {login && (
//         <LogIn
//           scrollHeight={scrollHeight}
//           setLogin={setLogin}
//           login={login}
//           setEnterUser={setEnterUser}
//         />
//       )}
//       {enterUser && (
//         <EnterUser
//           setEnterUser={setEnterUser}
//           enterUser={enterUser}
//           setLogin={setLogin}
//         />
//       )}
//       {cart && (
//         <Card
//           setCart={setCart}
//           setCountProductForCart={setCountProductForCart}
//           setCartCounterC={setCartCounterC}
//           cartCounterC={cartCounterC}
//         />
//       )}
//     </>
//   );
// }
import HeaderApp from "./headerApp";
import "./header.css";
import HeaderDown from "./headerDown";
import { useState, useEffect } from "react";
import FilterMenu from "./filterMenu";
import LogIn from "../logIn/logIn";
import EnterUser from "../enterUser/enterUser";
import Card from "../card/card";
import FilterMenuManu from "./filterMenuManu";
import { useLocation } from "react-router-dom";

export default function Header({
  setLogin,
  login,
  setEnterUser,
  enterUser,
  scrollHeight,
  cartCounterC,
  totalQuantity,
  setCartCounterC,
  likedCount,
}) {
  const [allBooks, setAllBooks] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(true);
  const [allManus, setAllManus] = useState(false);
  const [cart, setCart] = useState(false);
  const [countProductForCart, setCountProductForCart] = useState();
  const location = useLocation();
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isBooksFilterActive, setIsBooksFilterActive] = useState(false);
  const [isManusFilterActive, setIsManusFilterActive] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop + 10 && currentScrollTop > 180) {
        // Прокрутка вниз на більше ніж 10px і пройдено більше 180px
        setIsHeaderFixed(false);
      } else if (
        currentScrollTop < lastScrollTop - 10 &&
        currentScrollTop > 0
      ) {
        // Прокрутка вгору на більше ніж 10px
        setIsHeaderFixed(true);
      } else if (currentScrollTop <= 0) {
        // Коли досягнуто верху сторінки
        setIsHeaderFixed(false);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1100) {
        setWindowDimensions(false);
      } else {
        setWindowDimensions(true);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, allBooks]);

  const headerStyle = {
    position: isHeaderFixed ? "fixed" : "static",
    top: 0,
    width: "100%",
    zIndex: 1000,
    backgroundColor: isHeaderFixed ? "white" : "", // Затемнення фону при фіксації
    transition: "transform 0.8s ease-in-out, background-color 0.3s ease-in-out",
  };

  return (
    <>
      <div style={headerStyle}>
        <HeaderApp
          windowDimensions={windowDimensions}
          setWindowDimensions={setWindowDimensions}
          countProductForCart={countProductForCart}
          setLogin={setLogin}
          login={login}
          setCart={setCart}
          cartCounterC={cartCounterC}
          totalQuantity={totalQuantity}
          likedCount={likedCount}
        />
        <HeaderDown
          windowDimensions={windowDimensions}
          setWindowDimensions={setWindowDimensions}
          setAllBooks={setAllBooks}
          allManus={allManus}
          setAllManus={setAllManus}
          allBooks={allBooks}
          setIsBooksFilterActive={setIsBooksFilterActive}
          isBooksFilterActive={isBooksFilterActive}
          setIsManusFilterActive={setIsManusFilterActive}
          isManusFilterActive={isManusFilterActive}
        />
        {allBooks && (
          <FilterMenu
            allBooks={allBooks}
            setAllBooks={setAllBooks}
            windowDimensions={windowDimensions}
            setWindowDimensions={setWindowDimensions}
            setIsBooksFilterActive={setIsBooksFilterActive}
          />
        )}
        {allManus && (
          <FilterMenuManu
            setAllManus={setAllManus}
            windowDimensions={windowDimensions}
            setWindowDimensions={setWindowDimensions}
            setIsManusFilterActive={setIsManusFilterActive}
          />
        )}
        {login && (
          <LogIn
            scrollHeight={scrollHeight}
            setLogin={setLogin}
            login={login}
            setEnterUser={setEnterUser}
          />
        )}
        {enterUser && (
          <EnterUser
            setEnterUser={setEnterUser}
            enterUser={enterUser}
            setLogin={setLogin}
          />
        )}
        {cart && (
          <Card
            setCart={setCart}
            setCountProductForCart={setCountProductForCart}
            setCartCounterC={setCartCounterC}
            cartCounterC={cartCounterC}
          />
        )}
      </div>
    </>
  );
}
