import Footer from "../standartComponent/footer/footer";
import Header from "../standartComponent/header/header";
import CatalogProductComponent from "../standartComponent/productComponent/catalogProductComponent";
import Filter from "./filter";

import FirstBlockCat from "./firstBlockCat";
import YouLikeIt from "./youLikeIt";
import "../standartComponent/productComponent/productStyle.css";
import DiscountAndAction from "./discountAndAction";
import ViewProductCatalog from "./viewProductCatalog";
import Card from "../standartComponent/card/card";
import ListButtonCount from "./listButtonCount";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withFirebaseCollection from "../HOK/withFirebaseCollection";
import { AiOutlineApartment } from "react-icons/ai";
import { AiOutlineUnorderedList } from "react-icons/ai";
import css from "./catalog.module.css";
import { fetchProducts, fetchProductsAll } from "../../function/productsSlice";
import SatalogProductComponentsSmall from "../standartComponent/productComponent/catalogProductComponentsSmall";
import SmallProductCartTop from "../standartComponent/productComponent/SmallProductCartTop";
const Catalog = ({
  setVisitedProducts,
  visitedProducts,
  productsAll,
  data,
  setCartCounterC,
  setLikeCounterC,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  // const products = useSelector((state) => state.products.items);
  const searchResults = useSelector((state) => state.products.searchResults);

  const items = useSelector((state) => state.products.items);

  const products = searchResults.length > 0 ? searchResults : items;

  const filters = useSelector((state) => state.filters);
  const [changeVisible, setChangeVisible] = useState(true);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;

  const currentProducts = products.slice(startIndex, endIndex);
  useEffect(() => {
    if (filters.length === 0) {
      dispatch(fetchProductsAll());
    } else {
      dispatch(fetchProducts(filters));
    }
  }, [filters]);
  //const currentProducts = products.slice(startIndex, endIndex);

  const numberOfPages = Math.ceil(products.length / productsPerPage);
  const maxDisplayedPages = 5; // Максимальна кількість відображуваних сторінок
  const firstDisplayedPages = Math.max(
    currentPage - Math.floor(maxDisplayedPages / 2),
    1
  );
  const lastDisplayedPages = Math.min(
    firstDisplayedPages + maxDisplayedPages - 1,
    numberOfPages
  );

  const pageNumbers = [];
  for (let i = firstDisplayedPages; i <= lastDisplayedPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <Filter filters={filters} />
      <FirstBlockCat
        setCartCounterC={setCartCounterC}
        setLikeCounterC={setLikeCounterC}
      />
      <div className={css.wrapCatalogChanges}>
        {changeVisible && (
          <AiOutlineApartment
            className={css.catalogVisibleCss}
            onClick={() => setChangeVisible(false)}
          />
        )}{" "}
        {!changeVisible && (
          <AiOutlineUnorderedList
            className={css.catalogVisibleCss}
            onClick={() => setChangeVisible(true)}
          />
        )}
      </div>
      {!changeVisible &&
        currentProducts &&
        currentProducts.map((el, index) => {
          return (
            <CatalogProductComponent
              visitedProducts={visitedProducts}
              setVisitedProducts={setVisitedProducts}
              el={el}
              key={index}
              setCartCounterC={setCartCounterC}
              setLikeCounterC={setLikeCounterC}
            />
          );
        })}
      {changeVisible && currentProducts && currentProducts.length > 0 && (
        <SatalogProductComponentsSmall
          visitedProducts={visitedProducts}
          setVisitedProducts={setVisitedProducts}
          el={currentProducts[0]} // Виводимо тільки перший товар
          key={0}
          setCartCounterC={setCartCounterC}
          setLikeCounterC={setLikeCounterC}
        />
      )}
      {changeVisible && (
        <div className={css.wrapSmallProductTwoType}>
          {currentProducts &&
            currentProducts.slice(1).map((el, index) => {
              return (
                <SmallProductCartTop
                  el={el}
                  key={index + 1} // Оскільки перший елемент був видалений, додаємо +1 до індексу
                  setCartCounterC={setCartCounterC}
                  setLikeCounterC={setLikeCounterC}
                />
              );
            })}
        </div>
      )}
      <ListButtonCount
        pageNumbers={pageNumbers}
        handlePageClick={handlePageClick}
      />
      {/*<YouLikeIt/>*/}
      <DiscountAndAction />
      <ViewProductCatalog
        products={productsAll}
        setCartCounterC={setCartCounterC}
        setLikeCounterC={setLikeCounterC}
      />
    </>
  );
};
export default withFirebaseCollection("seo")(Catalog);
