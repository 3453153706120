
import './catalog.css'


export default function DiscountAndAction() {




    return(
        <div className="discountAndACWrapp">
<div className='discountAndACWrappFirst'>
<div className='freeDelyvery'>
Безкоштовна доставка від 1000 грн
</div>
<div className='procentSale'>
10% знижки від 700 грн
</div>
</div>
<div className='discountAndACWrappSecond'>
<div className='discountForNine'>15% знижки від 990 грн</div>
<div className='krohmalPackeg'>Фірмовий крохмаль пакет у подарунок</div>
<div className='smakolukForUser'>Смаколики для спільноти</div>


    
</div>
        </div>
    )
}