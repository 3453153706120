import css from "./userCabinet.module.css";
import { useNavigate } from "react-router-dom";
export default function BlockReader() {
  const navigate = useNavigate();
  const sendToF = () => {
    navigate(`/opt`);
  };
  const rucopus = () => {
    navigate(`/manuscriptCatalog`);
  };
  return (
    <div className={css.readerUserWrap}>
      <div className={css.readerUserWrapSmall}>
        <h1 className={css.aboutCompanyH1}>
          Підписатися на телеграм-канал "Час майстрів"
        </h1>
        <p className={css.paragInWrapBig}>
          "Клуб Майстрів". Члени клубу — це всі зареєстровані користувачі, що
          підписані на телеграм-канал видавництва. Нам важливо мати прямий
          контакт з читачами. Член клубу має знижку 5% і доступ до роздачі
          "слонів" (1 "слон" = 1 грн). Користуючись системою "слонів", можна
          зменшити суму до сплати у кошику. Читачі можуть брати участь у
          популяризації книг. Ви пишете відгук (мінімум 350 знаків про нашу
          книгу) і на ваш рахунок зараховуються "слони" в сумі відповідно до
          об'єму книги. Берете участь в Опікунській раді — нараховуються "слони"
          та надаються знижки 40% на опікувані книги. Допомагайте у створенні
          книги. Вам безоплатно надається доступ до прочитання рукописів
          (відкриває сторінку сайту "Рукописи"), ще до їхнього видання. Ваш
          відгук на рукопис допомагає видавництву обрати найкращий рукопис для
          подальшого видання.Якщо ви підписалися на канал Час майстрів в
          Телеграмі, але у вас відсутня знижка при замовлені наших книг, то будь
          ласка повідомте по цю проблему і надішлить нам посилання на ваш
          Телеграм на наш імейл sales@chasmaistriv.com.ua
        </p>
        <h1 className={css.aboutCompanyH1}>
          <a
            href="https://forms.gle/rihSi1qUbM94Gg7H9"
            target="_blanck"
            className={css.aboutCompanyH1}
          >
            Подати заявку до опікунської ради
          </a>
        </h1>
        <p className={css.paragInWrapBig}>
          Шукаємо опікунів нашими книгами. Що таке опікунська рада? Опікун, це
          читач, який зацікавився книгою і готовий її прочитати, а потім
          написати відгук. Опікун безоплатно отримує електронну версію
          опікуваної книги; 40% знижки протягом року на будь-яку кількість
          примірників опікуваної книги; 40% знижки на одну будь-яку книгу
          видавництва (знижка на один примірник однієї назви); "слони" за
          відгуки (одного "слона" можна обміняти при купівлі книги через сайт
          "Час майстрів" на 1 грн).
        </p>
        <h1 className={css.aboutCompanyH1}>
          <a
            href="https://t.me/chas_maistriv_chat"
            target="_blanck"
            className={css.aboutCompanyH1}
          >
            Написати в телеграм про гурт-замовлення
          </a>
        </h1>
        <p className={css.paragInWrapBig}>
          Замовити партію книг на групу, клас, подарунки співробітникам, сумісні
          покупки. Спеціальна ціна для гуртового замовлення. Обговорюєте умови з
          Лесею Кухарчук (+38 067 2315737 sales@chasmaistriv.com.ua). Зручний
          для гуртового замовлення{" "}
          <span className={css.spanLinfOpt} onClick={sendToF}>
            вибір книг на сторінці сайту "Прайс-гурт"(перехід на стор.).
          </span>{" "}
        </p>
        <h1 className={css.aboutCompanyH1} onClick={rucopus}>
          Рукописи
        </h1>
        <p className={css.paragInWrapBig}>
          Читати рукопис. Ви маєте доступ до безоплатного читання рукописів
          книг, які ми розглядаємо до видання. Таким чином ви допомагаєте
          видавництву знайти найкращий рукопис серед багатьох інших. Це ваш
          вклад у розбудову простору сучасної української літератури. Ви читаєте
          частину рукопису, якщо вам сподобається, то ви можете замовити доступ
          до повного тексту. Тільки після прочитання повного тексту, ви можете
          написати відгук і залишити побажання автору.
        </p>
      </div>
    </div>
  );
}
