import css from './info.module.css'
import srvd from '../../img/aboutPic.png'





export default function PublicOffer() {





    return(
        <div className={css.aboutWrapAll}>
            <div className={css.smallWrapAbout}>
                <h1 className={css.aboutCompanyH1}><a className={css.aboutCompanyH1} href="#section1">Згода  на обробку персональних даних</a><a className={css.aboutCompanyH1} href="#section2">/Публічна оферта покупця/Оферта про надання невиключної ліцензії на використання Твору</a><a className={css.aboutCompanyH1} href="#section3">Оферта про надання невиключної ліцензії на використання Твору</a></h1>
                <div className={css.wpaPicAbout}>
                  
                </div>
                <p  id="section1" className={css.paragInWrapBig}>Згода  на обробку персональних даних. Заповненням та підписанням даної анкети в електронній формі, надаю ТОВ «Видавництво Час Майстрів», юридична адреса: Україна, 03022, місто Київ, вулиця Васильківська, будинок 30, код за ЄДРПОУ 44250604, свою однозначну згоду включення моїх персональних даних у Базі персональних даних клієнтів «Клуб Майстрів» з метою забезпечення реалізації відносин у сфері реклами, маркетингу та збору персональних даних у комерційних цілях, забезпечення комунікацій з клієнтами, надання суб’єктам рекламних та/або інформаційних матеріалів щодо спеціальних пропозицій видавництва, вивчення попиту, інформування суб’єктів про нові товари, забезпечення реалізації цивільно-правових, податкових відносин та відносин у сфері бухгалтерського обліку. 
Надаю свою згоду на обробку моїх персональних даних у Базі персональних даних клієнтів «Клуб Майстрів», повідомлений про мету обробки моїх персональних даних, ознайомлений(а) з моїми правами як суб’єкта персональних даних, передбаченими Законом України «Про захист персональних даних», склад та зміст зібраних даних про мене, а також про умови передачі моїх персональних даних розпорядникам баз даних та третім особам.
Підписанням цієї анкети, засвідчую що надана мною інформація є достовірною та повною.
</p>
                <p  id="section2" className={css.paragInWrapBig}>Публічний договір (оферта) інтернет-магазину видавництва Час Майстрів<br/>

Наведений нижче текст є офіційною публічною пропозицією, (відповідно до ст. 641 Цивільного кодексу України) укласти Публічний Договір купівлі-продажу Товарів розміщених на Сайті «Видавництва Час Майстрів» (надалі — Договір).<br/>
Покупець приймає (акцептує) умови цієї пропозиції (оферти) в момент оформлення Замовлення шляхом натискання на кнопку "Підтвердити замовлення" на Сайті інтернет-магазину, що означає підтвердження Покупця про ознайомлення з текстом цього Договору та згоду з його умовами. Наявність будь-яких заперечень Покупця щодо надання, таким чином, своєї згоди звільняє Продавця від будь-якої відповідальності за невиконання умов цієї пропозиції і надає Продавцю право скасувати Замовлення в односторонньому порядку. Перед здійсненням купівлі книг на сайті «Видавництва Час Майстрів» уважно прочитайте цю Оферту.<br/>

1. Визначення термінів<br/>
1.1. «Видавництво Час Майстрів» – ТОВ «ВИДАВНИЦТВО «ЧАС МАЙСТРІВ»<br/>
03022, м. Київ, вул. Васильківська, 30<br/>
Код ЄДРПОУ 44250604<br/>
IПН 442506026502<br/>
Р/р № UA093052990000026003026815008 в АТ<br/>
КБ „ПриватБанк» м. Київ<br/>
Тел.0979043999<br/>
1.2. Продавець – «Видавництво Час Майстрів», ФОП Мехед , або інший суб'єкт підприємницької діяльності (юридична особа чи фізична особа-підприємець), Товар якого розміщено на Сайті за погодженням «Видавництва Час Майстрів».<br/>
1.3. Покупець – фізична особа, яка прийняла в повному обсязі та без виключень умови Договору (особа, яка здійснила акцепт Оферти) в порядку визначеному цим Договором.<br/>
1.4. Сторони – Продавець та Покупець.<br/>
1.5. Сайт - сукупність сторінок, доступ до яких здійснюється за допомогою веб-браузера, і програмних модулів, об'єднаних єдиною системою управління, розташованих в мережі Інтернет за адресою https://chasmaistriv.com.ua/ та призначений для надання інформації та оформлення замовлення Покупця на підставі цього Договору.<br/>
1.6. Товар - опублікований на сайті Інтернет-магазину перелік товарів, щодо яких вказуються ціна, назва, опис. Також товар може супроводжуватися його зображенням.<br/>
1.7. Контент - товар, що представлений на сайті https://chasmaistriv.com.ua/  в електронному (цифровому) форматі, що включає в себе текстові, зображувальні, аудіо або інші файли, що надані у різних форматах та призначені для використання Покупцем в особистих цілях шляхом відтворення (перегляду) на обладнанні Покупця.<br/>
1.8. Представник Покупця – фізична особа, яка пред’явить роздруковане замовлення або інший документ, що свідчить про укладання Договору з Продавцем.<br/>
1.9. Перевізник – юридична особа або фізична особа – підприємець, який прийняв на себе за Договором обов’язки по доставці Товару до пункту призначення, зазначеного Покупцем.<br/>

2. Предмет договору<br/>
2.1. Продавець зобов'язується на умовах та в порядку, визначених цим Договором, передати у власність Товар Покупцю, а Покупець зобов'язується прийняти Товар та сплатити його вартість.<br/>
2.2. Товар замовляється Покупцем виключно для особистих, сімейних, побутових потреб, не пов’язаних із здійсненням підприємницької діяльності.<br/>
2.3. Контент може бути використаний Покупцем виключно в особистих цілях шляхом відтворення (перегляду) виключно на обладнанні Покупця. При цьому, при використанні контенту Покупець не має права: - використовувати контент яким-небудь способом з метою передачі третім особам або забезпечення доступу третім особам до контенту; - використовувати контент в підприємницьких цілях або з метою отримання вигоди від будь-якого використання; - переробляти, поширювати, публічно показувати, публікувати в глобальній мережі Інтернет, імпортувати, здавати в прокат, публічно виконувати, повідомляти в ефір або по кабелю, доводити до загального відома контент; - розміщувати контент на будь-яких ресурсах таким чином, або вчиняти інші дії, за яких треті особи можуть отримати доступ до контенту.<br/>
2.4. Покупець гарантує Продавцю, що володіє необхідною дієздатністю, а також всіма правами і повноваженнями, необхідними і достатніми для укладання і виконання Договору відповідно до його умов.<br/>

3. Порядок замовлення товару<br/>
3.1. Покупець може оформити замовлення самостійно на сайті, на умовах цього Договору. Покупець оформляє Замовлення на Сайті шляхом додавання обраних Товарів у віртуальний «Кошик» та натиснувши кнопку «Оформити замовлення». Найменування, асортимент, кількість та ціна Товару, що є предметом цього Договору, визначаються у замовленні Покупця, розміщеному через електронний каталог.<br/>
3.2. При оформлені замовлення на сайті Покупець зобов’язаний надати про себе наступну інформацію: ● Ім'я, прізвище; ● електронну пошту. ● адресу доставки Товару; ● контактний номер телефону; ● та іншу необхідну інформацію.<br/>
3.3. Покупець несе відповідальність за достовірність наданої при оформленні замовлення інформації та її чистоту від претензій третіх осіб. У випадку наявності помилок та/або надання неповних даних в полях «Ім’я», «Прізвище», «Номер телефону» та інших, Продавець повністю звільняється від відповідальності за неналежне виконання замовлення.<br/>
3.4. Покупець вправі оформити замовлення на будь-який Товар, який представлений на сторінках Сайту. Кожний Товар може бути замовлений в будь-якій кількості. Виключення із зазначеного правила вказані при описі кожного Товару у випадку проведення акцій, зняття Товару з продажу та інше.<br/>
3.5. Після оформлення замовлення представник Продавця відправляє повідомлення на контактний email Покупця з підтвердженням прийняття замовлення. Отримання email-повідомлення Покупцем є підтвердженням прийняття замовлення. В подальшому представник Продавця може зв’язатися з Покупцем (по телефону або через електронну пошту) для уточнення інформації щодо умов замовлення та доставки. Відправка замовлень з післяплатою здійснюється лише після підтвердження номеру телефону клієнта.<br/>
3.6. Детальна інформація про замовлення з зазначенням найменування, ціни, кількості вибраного Товару, а також сума до оплати міститься в розділі “Мій кабінет” на сайті  та/або надсилається на електронну адресу Покупця після оформлення замовлення.<br/>
3.7. При неможливості замовлення, придбання та доставки Товару представник Продавця повідомляє про це Покупця (по контактному номеру телефону або через електронну пошту).<br/>
3.8. При відсутності Товару Покупець вправі замінити його іншим Товаром або анулювати замовлення.<br/>

4. Ціна товару та порядок здійснення оплати<br/>
4.1. Ціна Товару на сторінках Сайту зазначається в гривнях за одиницю Товару та є інформаційною.<br/>
4.2. Зазначена на Сайті ціна Товару може бути змінена Продавцем в односторонньому порядку. Ціна на замовлений Товар не підлягає зміні після підтвердження прийняття замовлення відповідно до п. 3.5. цього Договору.<br/>
4.3. Повна вартість замовлення складається із: - вартості Товарів (з усіх розділів сайту); - вартості послуг з доставки Товару.<br/>
4.4. Порядок здійснення оплати за Товар визначається у розділі «Оплата».<br/><br/>

5. Доставка товарів<br/>
5.1. Способи, порядок та строки доставки Товару зазначені на сайті в розділі «Доставка». Порядок та умови доставки замовленого Товару погоджуються Покупцем з представником Сайту.<br/>
5.2. Доставка Товарів, які були замовлені та придбані Покупцем, здійснюється Продавцем або третьою особою (Перевізником). При здійсненні доставки Товару, Товар передається безпосередньо Покупцю, або Представнику Покупця.<br/>
5.3. Для виконання зобов’язань за цим Договором Продавець має право залучати третіх осіб (юридичних осіб та/або фізичних осіб – підприємців) за договорами доручення, комісії, перевезення тощо.<br/>
5.4. Вартість доставки Товару в рамках кожного замовлення розраховується виходячи із ваги всіх замовлених Товарів, адреси доставки замовлення, тарифів на доставку, які описані на сайті в розділі «Доставка» та оплачується Покупцем.<br/>
5.5. Сторони домовились, що Продавець виконав свої зобов’язання за цим Договором після передачі (отримання) Покупцем замовлених Товарів.<br/>
5.6. Перехід ризиків та права власності на Товар від Продавця до Покупця відбувається в момент приймання-передачі Товару.<br/>

6. Відповідальність сторін<br/>
6.1. Сторони несуть відповідальність передбачену чинним законодавством України та цим Договором.<br/>
6.2. Продавець не несе відповідальності за шкоду, яка була нанесена Покупцю внаслідок неналежного використання ним Товарів, замовлених в інтернет-магазині.<br/>
6.3. Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов’язань за цим Договором на час дії обставин непереборної сили.<br/>
6.4. Всі спори та суперечки, які виникають при виконані Сторонами зобов’язань по цьому Договору, вирішуються шляхом переговорів. У випадку неможливості їх усунення, Сторони мають право звернутися за захистом своїх прав та інтересів в судовому порядку.<br/>

7. Строк дії договору<br/>
7.1. Договір діє з моменту його укладення, тобто з моменту прийняття (акцепту) Договору Покупцем, в порядку передбаченому цим Договором.<br/>
7.2. Договір діє до моменту повного виконання Сторонами своїх зобов'язань за Договором.<br/>
7.3. Сторони можуть достроково розірвати Договір за взаємною згодою.<br/>

8. Інші умови<br/>
8.1. Продавець має право в односторонньому порядку змінювати умови цього Договору, без попереднього повідомлення про це Покупця. Нова редакція цього Договору вступає в силу з моменту опублікування її на Сайті.<br/>
8.2. Всі інформаційні матеріали, які представлені в інтернет-магазині, носять довідковий характер та не можуть в повній мірі передати інформацію про властивості та характеристики Товару, включаючи колір, розмір та форми та іншого. У випадку виникнення у Покупця питань, які стосуються властивостей, характеристик Товару та іншого, Покупець має можливість, перед оформленням замовлення, звернутися до Продавця за телефонами зазначеними на сайті для з’ясування повної інформації про Товар.<br/>
</p>
<p  id="section3" className={css.paragInWrapBig}>Оферта про надання невиключної ліцензії на використання Твору <br/>

Публічний договір (оферта) інтернет-магазину видавництва Час Майстрів, надалі Ліцензіат, наведений нижче текст є офіційною публічною пропозицією, (відповідно до ст. 641 Цивільного кодексу України) укласти Публічний Договір про надання Твору для використання на умовах невиключної ліцензії на Сайті «Видавництва Час Майстрів» (надалі — Договір).<br/>

Користувач сайту який надає свій Твір для опублікуванні на Сайті, далі Ліцензіар, приймає (акцептує) умови цієї пропозиції (оферти) в момент реєстрації на сайті в ролі Автора і подальшого надсилання pdf Твору електронною поштою на адресу  glavred@chasmaistriv.com.ua, що означає підтвердження Ліцензіара про ознайомлення з текстом цього Договору та згоду з його умовами. Наявність будь-яких заперечень Ліцензіара щодо надання, таким чином, своєї згоди звільняє Ліцензіата від будь-якої відповідальності за невиконання умов цієї пропозиції і надає Ліцензіату право скасувати договір в односторонньому порядку і видалити Твір Ліцензіара, особисту сторінку Ліцензіара з сайту. Перед наданням Твору для публікації на сайті «Видавництва Час Майстрів» уважно прочитайте цю Оферту.<br/>

1. ВИЗНАЧЕННЯ ТЕРМІНІВ<br/>
1.1. «Видавництво Час Майстрів» – ТОВ «ВИДАВНИЦТВО «ЧАС МАЙСТРІВ»<br/>
03022, м. Київ, вул. Васильківська, 30; Код ЄДРПОУ 44250604; IПН 442506026502; Р/р № UA093052990000026003026815008 в АТ; КБ „ПриватБанк» м. Київ; Тел.0979043999<br/>
1.2. Ліцензіат – «Видавництво Час Майстрів», ФОП Мехед , або інший суб'єкт підприємницької діяльності (юридична особа чи фізична особа-підприємець), яка розміщує наданий Ліцензіаром Твір.<br/>
1.3. Ліцензіар – фізична особа, яка прийняла в повному обсязі та без виключень умови Договору (особа, яка здійснила акцепт Оферти) в порядку визначеному цим Договором.<br/>
1.4. Сторони – Ліцензіат та Ліцензіар.<br/>
1.5. Сайт - сукупність сторінок, доступ до яких здійснюється за допомогою веб-браузера, і програмних модулів, об'єднаних єдиною системою управління, розташованих в мережі Інтернет за адресою https://chasmaistriv.com.ua/ та призначений для надання інформації та оформлення замовлення Покупця на підставі цього Договору.<br/>
1.6. Твір - опублікований на Сайті pdf файл з текстом створеним особистою працею Ліцензіара. <br/>

2. ПРЕДМЕТ ДОГОВОРУ<br/>
2.1.  З ціллю популяризація імені Ліцензіара, отримання відгуків на Твір і подальшого розгляду комерційного використання Твору Ліцензіатом, в залежності від отриманих відгуків, Ліцензіар надає Ліцензіатові дозвіл на використання Твору на умовах невиключної ліцензії до моменту видалення Твору з сайту, на території всього Світу, наступними способами: <br/>    
відтворення, опублікування українською мовою в цифровій формі,<br/>
публічне виконання та публічне сповіщення,<br/>
розповсюдження примірників Твору на території всіх країн світу,<br/>
подання Твору до загального відома публіки таким чином, що її представники можуть здійснити доступ до Твору з будь-якого місця і у будь-який час за їх власним вибором (інтерактивне надання доступу).<br/>
2.2. Наклад необмежений.<br/>
2.3. Ліцензіат не захищає  цифрову форму Твору від копіювання скачування і подальшого несанкціонованого розповсюдження. Ліцензіат не використовує сучасні технічні засоби захисту.<br/>
2.4. Ліцензіар розуміє що користувачі сайту можуть читати твір на Сайті, або скачувати його для читання Твору зручним їм способом, таким чином Ліцензіар згоден з тим, що користувачі сайту, публіка, має право на використання Твору шляхом перегляду, відтворення (у тому числі копіювання та друк копій) та інші права виключно з метою особистого некомерційного використання.<br/>
2.5. Ліцензіар і Ліцензіат домовились, що Твір розповсюджується на безоплатній основі і Сторони не можуть отримати матеріальну вигоду по цього договору.<br/>
 
3. ПОРЯДОК ВИКОНАННЯ ДОГОВОРУ.<br/>
3.1. Ліцензіар реєструється на сайті в ролі Автора. Заповнює анкету Автора, Заповнює анкету Твору.<br/>
3.2. Ліцензіар надсилає Ліцензіату Твір в pdf форматі електронною поштою на адресу  glavred@chasmaistriv.com.ua. <br/>
3.3. Ліцензіат попередньо знайомиться з Твором і надає згоду на публікацію і публікує анкету Автора і Твір на сайті.<br/>
3.4. Ліцензіат в односторонньому порядку без пояснень, може відмовити в публікації Твору, по причині якості Твору, не повністю заповнених даних в анкеті Автора і Анкеті Твору.<br/>
3.5. Ліцензіар може відкликати публікацію Твору надіславши таку вимогу електронною поштою на адресу Ліцензіата  glavred@chasmaistriv.com.ua. <br/>
 
4. ПРАВА.<br/>
4.1. Публікація Твору має містити ім’я автора - [Ліцензіара] <br/>
4.2. Сайт забезпечує зберігання Твору для просування та популяризації серед публіки, а також у соціальних мережах та інших каналах комунікації, виключаючи його продаж.<br/>

5. ВИНАГОРОДА. РОЯЛТІ<br/>
5.1. Ліцензіар розміщує свій Твір на сайті безоплатно.<br/>
5.2. Ліцензіат використовує Твір на умовах невиключної ліцензії безоплатно.<br/>
5.3. Ліцензіар заявляє, що передбачені цим договором безоплатне розміщення Твору на сайті і безоплатне використання Твору Ліцензіатом є справедливими і співмірними, по причині того, що головна ціль такого використання є отримання відгуків і популяризація імені Ліцензіара, і прийняття рішення на основі відгуків щодо подальшого його комерційного використання, а не продажа Твору і отримання матеріальної вигоди.<br/>
 
6. ГАРАНТІЇ.<br/>
6.1. Ліцензіар гарантує, що він має право на укладення договорів на умовах невиключної ліцензії щодо Твору і в разі виникнення будь-яких претензій з боку третіх осіб за фактом укладення цього договору зобов'язується врегулювати їх самостійно та за свій рахунок.<br/>
6.2. Ліцензіат не гарантує захисту примірників Твору від несанкціонованого копіювання Твору споживачами.<br/>

7. ПРАВИЛА ПУБЛІКАЦІЇ ТВОРІВ<br/>
7.1. На сайті заборонена до публікації інформація, яка:<br/>
містить погрози, дискредитує, порушує права, ображає, принижує честь і гідність чи ділову репутацію або порушує недоторканність приватного життя інших користувачів або третіх осіб;<br/>
містить інформацію обмеженого доступу, включаючи, але не обмежуючись, державною та комерційною таємницями;<br/>
містить інформацію, яка пропагує і/або сприяє розпалюванню расової, релігійної, етнічної ненависті або ворожнечі, політичної ворожнечі, пропагує ідеологію нацизму, або інших тоталітарних режимів, расової переваги, містить негативні висловлювання про будь-які країни, народи, релігії, політику, принижують національну гідність, в тому числі за ознакою національної приналежності або політичних поглядів;<br/>
пропагує бузувірство, блюзнірство, неповагу до національних і релігійних святинь;<br/>
принижує особистість, є проявом знущання з приводу фізичних вад (каліцтва), з душевнохворих, літніх людей;<br/>
містить екстремістські матеріали;<br/>
пропагує злочинну діяльність або містить практичні поради, інструкції або керівництво по здійсненню злочинних дій;<br/>
містить літературно невиправдану жорстокість по відношенню до людей чи тварин;<br/>
пропагує наркоманію, токсикоманію, алкоголізм, тютюнопаління та інші шкідливі звички;<br/>
містить детальний опис способів суїциду, будь-яке підбурювання до його здійснення, зображення сцен суїциду та спроб його здійснення;<br/>
містить зображення і тексти або сцени сексуального характеру за участю неповнолітніх, інцест;<br/>
вихваляє і/або виправдовує сексуальне насилля;<br/>
зображує і вихваляє відверто непристойні форми сексуальних стосунків, зоофілію;<br/>
рекламує криптовалюти;<br/>
носить шахрайський характер;<br/>
містить оголошення збору коштів на будь-які цілі або потреби.<br/>
7.2. На сайті заборонена публікація порнографічних матеріалів (в тому числі і текстових). Порнографічними матеріалами є матеріали, які створені лише для сексуального збудження і не мають жодної художньої цінності, вульгарно-натуралістичні, цинічні, містять непристойний опис статевих актів, самоцільний, детальний опис геніталій, антитетичні сцени статевого акту, сексуальних збочень, ображають честь і гідність людини, спонукаючи негідні інстинкти.<br/>
7.3. На сайті не можна розміщувати графічні матеріали (обкладинки, малюнки, світлини в записах блогів та текстах книг і т.д.), в яких присутні зображення:<br/>
повністю оголених та напівоголених людей;<br/>
людей в прозорому або надмірно відвертому одязі;<br/>
людей в непристойних позах;<br/>
оголені сідниці, геніталії чи жіночі груди.<br/>
Будь-який Твір, який містить інформацію, що не призначена для користувачів молодше 18 років, заборонений для публікації. До таких Творів , належать твори еротичного характеру та ті, що містять елементи насильства та жорстокості: <br/>
опис сцен сексу, оголеності;<br/>
опис важкого фізичного, вербального, сексуального або емоційного насилля;<br/>
детальний опис катастроф, жахів.<br/>
7.4. Ліцензіар самостійно, користуючись положеннями Закону України” Про захист суспільної моралі” та “Правилами публікації авторського контенту”, визначає чи є вікові обмеження твору перед початком його публікації на сайті.<br/>
7.5. Ліцензіат має право:<br/>
блокувати доступ до будь-якого твору, розміщеного Сайті;<br/>
примусово призначати твору вікове обмеження “18+”;<br/>
видаляти твір без попереднього сповіщення за серйозне порушення Угоди користувача;<br/>
видаляти записи блогу, коментарі за порушення Угоди користувача;<br/>
блокувати Ліцензіару доступ до Сайту.<br/>

8. ВІДПОВІДАЛЬНІСТЬ СТОРІН.<br/>
8.1. Сторони за цим договором відповідають згідно діючого законодавства України.<br/>
8.2. В разі поширення «піратських копій» Твору через використання Ліцензіатом Твору в цифровій формі, останній не несе відповідальність перед  Ліцензіаром за таке порушення.  <br/>
8.3. Ліцензіар несе відповідальність перед третіми особами, що заявили свої права на твір, і відшкодовує Ліцензіату усі витрати, спричинені позовами третіх осіб про порушення авторських та інших прав на твір.<br/>
8.4. Ліцензіар несе особисту відповідальність за будь-яку інформацію, яку надає Ліцензіату для розміщення на Сайті, повідомляє іншим Користувачам, а також за будь-які взаємодії з іншими Користувачами, що здійснюються на свій ризик.<br/>
8.5. Ліцензіат не несе відповідальність за будь-яку інформацію, яку надає Ліцензіар для розміщення на Сайті. Ліцензіат не вичитує Твір, не редагую, не вносить зміни і не рецензує його. Ліцензіар розміщує Твір наданий Ліцензіатом покладаючись на відповідальність Ліцензіара за інформацію подану в Творі, що вона не завдає шкоди дітям, є законною, відповідає вимогам та правилам розміщення на даному Сайті, а також законам України та звичайним нормам моралі. <br/>
 
9. ТЕРМІН ДІЇ ДОГОВОРУ.<br/>
9.1.  Договір діє з моменту його укладення, тобто з моменту прийняття (акцепту) Договору Ліцензіаром, в порядку передбаченому цим Договором.<br/>
9.2. Договір діє до моменту наявності Твору у доступі на сайті.<br/>
9.3. Публікація твору на сайті припиняється, якщо Ліцензіар надішле лист з вимогою про припинення публікації електронною поштою на адресу Ліцензіата  glavred@chasmaistriv.com.ua, або за одностороннім рішенням Ліцензіата.<br/>

10. ПОВІДОМЛЕННЯ.<br/>
10.1. Сторони приймають та погоджуються із тим, що будь-яке листування, повідомлення можуть вестися через електронну пошту  glavred@chasmaistriv.com.ua. <br/>
</p>
            </div>
        </div>
    )
}
