import css from "./info.module.css";
import srvd from "../../img/aboutPic.png";

export default function Delivery() {
  return (
    <div className={css.aboutWrapAll}>
      <div className={css.smallWrapAbout}>
        <h1 className={css.aboutCompanyH1}>
          <a className={css.aboutCompanyH1} href="#section1">
            Доставка
          </a>
          /
          <a className={css.aboutCompanyH1} href="#section2">
            Самовивіз
          </a>
          /
          <a className={css.aboutCompanyH1} href="#section3">
            Де пощупати наші книги
          </a>
          /
          <a className={css.aboutCompanyH1} href="#section4">
            {" "}
            Книги ЧМ на інших ресурсах
          </a>
        </h1>
        {/* <div className={css.wpaPicAbout}>
                    <p className={css.paragInWrap}>
                    Видавницво <span className={css.aboutSpan}>Час Майстрів</span> представлена на ринку дитячої літератури України вже <span className={css.aboutSpan}>понад 16 років.</span> Але понад 20 років потому ми вже стрясали ринок навчальної літератури своїми книжковими ноу-хау. Видавництво «Час майстрів» прагне створювати інноваційні книги, які допомагають дітям еволюціонувати. Книги, від яких дітям важко відірватися, є для нас необхідною, але не достатньою умовою.
                    </p>
                    <div className={css.imgAboutWrap} >
                        <img src={srvd} className={css.imgAbout}/>
                    </div>
    </div>*/}
        <p id="section1" className={css.paragInWrapBig}>
          Доставка.
          <br /> Ціни, зазначені на сайті, є остаточними й не вимагають доплат
          при стандартних умовах постачання. Усі податки входять у вартість
          товару. Так само пакування замовлень повністю на видавництві - ви не
          платите при доставці нічого додатково!
          <br />
          Доставка по Україні:
          <br />
          Книжки, замовлені в інтернет-магазині, надсилаємо виключно компанією
          "Нова пошта" до ваших найближчих віділень чи поштоматів із складу у
          Вінницькій обл. Можлива адресна доставка кур'єром Нової пошти.
          <br />
          Доставку книжок оплачує замовник за цінами компанії-перевізника.
          <br />
          Для замовлень вартістю від 1000 грн доставка є безкоштовною.
          <br />
          Сума мінімального замовлення в інтернет-магазині – 100 грн. <br />
          Вартість доставки залежить від ваги книжок і відстані від Києва до
          вашого міста.
          <br />
          Ми не можемо відповідати за якість роботи поштових перевізників, але у
          разі проблем із доставкою намагаємося надавати замовникові всіляку
          підтримку.
          <br />
          <br />
          Оплата:
          <br />
          На жаль, посилки, відправлені післяплатою, замовники не завжди
          забирають із пошти, й тоді інтернет-магазинам доводиться оплачувати
          всі поштові послуги. Для нас це часто вартість надісланої книжки. Тому
          ми працюємо суворо за ПОВНОЮ ОПЛАТОЮ на розрахункові рахунки
          Приватбанку. Реквізити надсилаємо після оформлення замовлення.
          <br />
          Повернення товару
          <br />
          Відповідно до Постанови Кабінету Міністрів України від 19 березня 1994
          р. № 172 "Про реалізацію окремих положень Закону України "Про захист
          прав споживачів" друковані видання належної якості не підлягають
          обміну, поверненню.
          <br />
          У випадку виявлення поліграфічного браку книжкової продукції
          Продавець, за бажанням Покупця, здійснює обмін товару на товар
          належної якості, або здійснює повернення коштів у розмірі вартості
          повернутої продукції. Поліграфічний брак – це брак, отриманий
          внаслідок недотримання технології виробництва, що призводить до
          перекручування або втрати інформації: розмазування фарби,
          непродруковування фарби, нечіткий друк, склеєні сторінки, нерівне
          обрізання, перевернуті аркуші, відсутність аркушів, або їх повторення,
          невідповідність назви книжки на обкладинці її змісту, тощо).
          <br />
          Умови замінювання бракованої продукції
          <br />
          Видавництво "Час майстрів" гарантує замінювання бракованої продуції на
          продукцію, що відповідає заявленій якості.
          <br />
          Для цього необхідно:
          <br />
          1) відкрити посилку у поштовому відділенні;
          <br />
          2) сфотографувати брак так, щоб було видно книжку повністю та інтер’єр
          поштового відділення;
          <br />
          3) сфотографувати сам брак зблизька, великим планом;
          <br />
          4) сфотографувати паковання та пошкодження паковання, щоб встановити
          причини браку: чи не пошкодив книжку перевізник у процесі доставки;
          <br />
          5) якщо пошкодження книжки виникли з вини перевізника, запросити
          працівника пошти, щоб він це зафіксував;
          <br />
          6) того ж дня, коли посилку було отримано у поштовому відділенні,
          повідомити видавництво про браковану продукцію.
          <br />
          7) повідомлення про брак надсилайте на електронну адресу
          lesia.chasmaistriv@gmail.com
          <br />
          Увага! Заяви про отримання бракованої продукції видавництво приймає
          лише в день її отримання і лише з фотографіями на тлі поштового
          відділення.
          <br />
        </p>
        <p id="section2" className={css.paragInWrapBig}>
          Самовивіз. На сьогоднішній день можливість самовивозу - відсутня.
          Після перемоги ми будемо раді знову бачити вас у нашому шоу-румі.
          <br />
          ПОТРЕБУЄ ПЕРЕВІРКИ ЗОРЯНИ! Любі читачі, нас часто запитують, де можна
          купити наші книжки, окрім інтернет-магазину та книжкових виставок.
          Поступово ми будемо викладати список магазинів у різних містах, які
          співпрацюють безпосередньо з нашим видавництвом. м. Вишнівець <br />
        </p>
        <p id="section3" className={css.paragInWrapBig}>
          Де пощупати наші книги Мережа магазинів «Дім книги» <br />
          Магазин №9 <br />
          м-д Шевченка, 28
          <br />
          тел. (067)184-22-97
          <br />
          shop9@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-18.00, Сб-Нд.: вихідний
          <br />
          м. Вінниця
          <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №15 <br />
          вул. Інтернаціональна, 3/2
          <br />
          тел. 0432-65-65-99.35-60-57.067-350-09-17
          <br />
          shop15@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00-19.00 Нд.-вихідний
          <br />
          Книгарня "ТУТ" <br />
          ТРЦ "Мегамолл"
          <br />
          2-й ярус, праве крило (біля "Галактики")
          <br />
          Магазин №17 <br />
          вул. Коцюбинського, 33а
          <br />
          тел.0432-26-78-25.067-350-48-07
          <br />
          shop17@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-19.00 без вихідних
          <br />
          Магазин №23 <br />
          вул. Келецька, 78
          <br />
          тел. 0432-65-65-90
          <br />
          shop23@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00 -19.00 без вихідних
          <br />
          м. Гайсин
          <br />
          Магазин "КіндерЛенд" <br />
          ТРЦ "Європейський", 1-й поверх
          <br />
          м. Дніпропетровськ <br />
          "Книгарня «ВіЗ»" <br />
          просп. К. Маркса, 18 (поблизу Історичного музею)
          <br />
          посилання на сайт: http://viz-knyg.com.ua
          <br />
          Галерея-книгарня "Чорна ящірка" <br />
          пл. Красная, 3<br />
          «Книгарня Є» <br />
          вул. Володимира Мономаха, 7<br />
          м. Житомир <br />
          Магазин «Світ книг», «Знання» <br />
          вул. Київська 17/1
          <br />
          тел. (0412)472752
          <br />
          Магазин «Книги + CD», «Мегакнига», «Книжний магазин» <br />
          Корчев пров.Скорульського,8 <br />
          10014, вул. Михайлівська 11/2
          <br />
          тел. (0412)418339
          <br />
          м. Запоріжжя
          <br />
          "Папирус"
          <br />
          пр. Соборный, 116
          <br />
          "Буква" <br />
          пр. Ленина, 147, ТЦ "Украина"
          <br />
          тел. (061) 701-70-89; (061) 701-70-08
          <br />
          Книгарня "Є" <br />
          пр. соборный, 137
          <br />
          тел. (094) 885-39-03
          <br />
          м. Київ
          <br />
          Шоу-рум видавництва
          <br />
          Метро "Площа Льва Толстого"
          <br />
          вул. Велика Васильківська 29/31 (у внутрішньому дворику 1 поверх
          двоповерхової будівлі).
          <br />
          «Книгарня Є»
          <br />
          вул. Лисенка,3; (044) 235-88-50 з 9:00 до 21:00, без вихідних
          <br />
          вул. Спаська, 5; (044) 351-13-38 з 9:00 до 21:00, без вихідних
          <br />
          пр. Повітрофлотський, 33/2; (044) 275-67-42 з 9:00 до 21:00, без
          вихідних
          <br />
          бульвар Лесі Українки, 24
          <br />
          просп. Бажана, 16-Д
          <br />
          «Сяйво книги» <br />
          вул. Велика Васильківська,6; <br />
          тел. (044)235-07-49; 221-86-29; 235-03-29 <br />
          Магазин «Читайка»
          <br />
          вул. Вербова, 17, територія книжкового ринку, станція метро "Петрівка"
          <br />
          тел. (044) 351-14-99
          <br />
          Магазин "Знайка" <br />
          вул.Ярославська, 35/35 (м. Контрактова площа) <br />
          тел. (044) 425 16 90 ;<br />
          режим роботи: пн-пт з 10.00 до 20.00, сб-нд 10.00 до 18.00.
          <br />
          Детский центр «KidsWill» (Кидсвиль) <br />
          вул. Заболотного, 37, ТЦ АrtMall, 2-й поверх
          <br />
          тел. (067) 232-41-40 <br />
          "Book*Ашка" <br />
          вул. Дмитрівська 25А
          <br />
          тел. +38 (068) 038-58-34, режим роботи: пн.-пт. 10:00 – 20:00, сб.-нд.
          11:00 – 19:00 <br />
          Магазин «Азбука счастья» <br />
          вул.Лебєдєва -Кумача 7 "Азбука счастья"
          <br />
          тел. (096) 912-30-08
          <br />
          «Книгарня 1»
          <br />
          просп. Московский 10-Б, <br />
          тел. (044) 351-15-14
          <br />
          Книгарня "Довженко"
          <br />
          ул. Бульварно-Кудрявская ( Воровского) 43А.
          <br />
          тел. 050 344 0646
          <br />
          Магазин «Чуланчик»
          <br />
          ул. Десятинный переулок, 7<br />
          режим работы: с 10 до 21:00
          <br />
          Магазин «Чулан»
          <br />
          ул. Кудрявская, 2а
          <br />
          тел. (044) 272-32-41, 096-267-58-61
          <br />
          «Дом образования и культуры "Мастер Класс» <br />
          ул. Богдана Хмельницкого 57Б
          <br />
          тел. (044) 594-10-63
          <br />
          Кафе "Имбирь"
          <br />
          вул. Жилянська, 7<br />
          тел. (044) 287-61-80
          <br />
          режим роботи: 8.00 - 22.00
          <br />
          «Моя книжкова полиця»
          <br />
          вул. Пушкінська 7 <br />
          тел. (095)0088233
          <br />
          Магазин «Трамвай» <br />
          вул.Пушкінська, 21 (парк Шевченко)
          <br />
          8:00–20:00 пн-пт, 9:00–21:00 сб, нед (крім зимових месяців)
          <br />
          «Наш формат»
          <br />
          пр-к Белинського, 5 (на пр-те Шевченка)
          <br />
          пн-пт 09:00-19:00; сб 10:00-17:00
          <br />
          тел. (044) 234-82-25 <br />
          «Навчальна книга - Богдан» <br />
          Пр-т Ю.Гагаріна, 27, 1-й поверх <br />
          тел. (044) 296-89-56, (044) 292-89-42
          <br />
          nk-bogdan@ukr.net
          <br />
          Пн-Пт: 10.00-18.00 (без перерви) Сб: 10.00-17.00(без перерви) Нд-вих.
          <br />
          «Книгарня»
          <br />
          на території Киево-Могил. Акад.
          <br />
          Контрактова пл.,4
          <br />
          тел. (044) 581-52-23
          <br />
          «Eurostar» <br />
          ТРК «Метроград» вул. Бассейна,½; тел. (044) 247-56-56 <br />
          ТЦ «Домосфера» Столичне шосе, 101; тел. (044) 252-74-12
          <br />
          «Лавка Бабуїн» <br />
          вул.Золотоворітська 2а, офіс 24а <br />
          Контактний телефон: +38 (044) 369 3335
          <br />
          «Воир Н» <br />
          вул. Борщагівська 174\32; <br />
          тел. (044) 599-14-15
          <br />
          Книжный интернет магазин
          <br />
          http://www.knigograd.com.ua/
          <br />
          044-499-0-979
          <br />
          063-233-0-299
          <br />
          096-766-0-311
          <br />
          050-413-64-94
          <br />
          Дитячий магазин "KIOVIA"
          <br />
          (ТЦ Паралель, (двор))
          <br />
          вул. Мішуги,10 (район м. Позняки)
          <br />
          тел: (050)9260109
          <br />
          (095)1780086
          <br />
          м. Коростень
          <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №8 <br />
          вул. Красіна, 4 <br />
          тел. (04142) 4-10-03; 096-35-97-742;
          <br />
          shop8@bohdan-books.com
          <br />
          Пн.-Пт.: 9,00-18-00 Сб-Нд 9.00-17.00
          <br />
          м. Ладижин
          <br />
          Книгарня "ТУТ" <br />
          ТРЦ "Європейський", 2-й поверх <br />
          м. Львів <br />
          Книгарня "Є" <br />
          просп. Свободи, 7 <br />
          тел. (032) 235-73-68
          <br />
          Книгарня "Є" <br />
          вул. Галицька, 9 <br />
          тел. (032) 235-70-06
          <br />
          Книгарня "Є" <br />
          вул. Т. Костюшка, 5 (вхід із вул. січових Стрільців, 17) <br />
          тел. (032) 297-01-90
          <br />
          Книгарня "Є" <br />
          вул. Коперніка, 11
          <br />
          "Буква" <br />
          вул. Дорошенко, 11 <br />
          тел. (032) 261-57-00
          <br />
          "Книгарня імені Шашкевича" <br />
          вул. Личаковська, 22 <br />
          тел. (032) 275-00-50
          <br />
          Режим роботи: з 9:00 до 18:00 пн-пт; з 10:00 до 15:00 сб
          <br />
          "Книгарня імені Б.І. Антонича" <br />
          вул. Степана Бандери, 33 <br />
          тел. (032) 237-97-48
          <br />
          Режим роботи: з 9:00 до 18:00 пн-пт; з 10:00 до 15:00 сб
          <br />
          "Сучасна українська книгарня" <br />
          пл. Галицька, 12 <br />
          тел. (032) 235-51-77
          <br />
          м. Луцьк <br />
          Мережа магазинів «Дім книги» <br />
          вул. Конякіна 37А <br />
          «Книгарня Є»
          <br />
          вул. Лесі Українки 30 <br />
          «Буква»
          <br />
          вул. Сухомлинського, 1, ТРЦ "Порт Сіті" <br />
          м. Миколаїв
          <br />
          «Книголюб»
          <br />
          вул. Червоних Майовщиків,4
          <br />
          тел.(0512)414738; (0512)414738 <br />
          м. Миргород <br />
          «Сторінка»
          <br />
          ул. Гоголя, 96
          <br />
          тел. 063355240
          <br />
          м. Монастириськ <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №13 <br />
          вул.Шевченка, 36 <br />
          тел. 03555-2-16-37, 096-941-36-18
          <br />
          shop13@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-17.00, без обіду Сб, Нд.: вихідний
          <br />
          м. Мукачево <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №5 <br />
          вул. Миру, 19а <br />
          тел. 067-350-39-23
          <br />
          shop5@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-18.00, Сб.: 9.00-15.00, Нд.: вихідний
          <br />
          м. Івано-Франківськ
          <br />
          Магазин "Книгарня Є"
          <br />
          вул. Вул. Незалежності, 31 <br />
          м. Одеса <br />
          "Буква"
          <br />
          вул. Дерібасівська, 14 <br />
          тел.(0482) 35-84-04; (0482) 35-84-05
          <br />
          «Акварель», ТРЦ "Сити Центр" <br />
          просп. Небесной сотни, 2 <br />
          тел. (048) 705-51-21
          <br />
          https://akvarel-n.com.ua/about/stores/
          <br />
          «Акварель», ТРЦ "Сити Центр Котовский"
          <br />
          ул. Давида Ойстраха, 32
          <br />
          тел. (048) 705-30-95
          <br />
          «Акварель», ТРЦ "Среднефонтанский"
          <br />
          пер. Семафорный, 4<br />
          тел. (048) 705-34-36
          <br />
          Рынок «7 км»
          <br />
          Одесская обл. Рынок "7 км", СТРП-1, №№ 106, 108
          <br />
          тел. 0674861587, 0935215888
          <br />
          «Учбова книга»
          <br />
          ул. Маршала Жукова
          <br />
          тел. (048) 233-60-68
          <br />
          Бук-маг «БУК»
          <br />
          пер. Адмирала Жукова 3/7
          <br />
          Большой БУК «концептуальное пространство», маг. №1
          <br />
          ул. Екатерининская, 27, ТЦ Кадорр, 5 этаж
          <br />
          тел. 0931978077
          <br />
          Магазин №2
          <br />
          ул. Марсельская, 35Б
          <br />
          м. Радомишль <br />
          «Книги» <br />
          Соборний Майдан 6<br />
          м. Рівне <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №18 <br />
          вул. Короленка, 2 <br />
          тел. 0362-40-01-50, 067-350-18-99
          <br />
          shop18@bohdan-books.com
          <br />
          Пн. -Пт.: 10.00 -19.00, Сб.- Нд.: 10.00 - 17.00
          <br />
          м. Славута <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №4 <br />
          вул. Я.Мудрого, 34/2 <br />
          тел. 03842-2-21-13; 098-604-58-23; 096-750-26-73
          <br />
          shop4@bohdan-books.com
          <br />
          Вт.-Пт.: 9.00-18.00, Сб-Нд.: 9.00-15.00, Пн.: вихідний <br />
          м. Тернопіль
          <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №1
          <br />
          вул. Руська, 14
          <br />
          тел.068-105-15-31
          <br />
          shop1@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-18.00, Сб.: 9.00-15.00, Нд.: вихідний
          <br />
          Магазин №2
          <br />
          вул. Злуки, 37
          <br />
          тел.0352-51-06-65
          <br />
          shop6@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-19.00, Сб.: 10.00-15.00, Нд.: вихідний <br />
          Магазин №6 <br />
          вул. Й.Сліпого, 1<br />
          тел.0352-43-03-71
          <br />
          shop2@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00-19.00, Сб.,Нд.: 10.00-15.00
          <br />
          Магазин №16 <br />
          вул. Коперника, 19
          <br />
          тел.0352-43-01-39
          <br />
          shop16@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00-19.00, Сб., Нд: 9.00-15.00
          <br />
          Магазин №20 <br />
          пр. Злуки, 37
          <br />
          тел. 067-350-14-67,0352-42-81-10
          <br />
          knuga_po6ta@ukr.net
          <br />
          Пн.-Пт.: 9.00-18.00, Сб. Нд.: вихідний
          <br />
          Гуртовня <br />
          вул. Подільська, 44
          <br />
          тел. 0352-43-42-72; 25-16-00; 25-56-45; 52-37-65 067-54-80-57,
          8-067-350-25-49, 067-354-80-57
          <br />
          dk@bohdan-books.com
          <br />
          Пн.-Пт.: 9.00-18.00, Сб.:9.00-13.00, Нд.: вихідний
          <br />
          м. Теребовля <br />
          <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №7 <br />
          вул. кн.Василька, 105
          <br />
          03551-2-38-44; (097)443-34-47 <br />
          shop7@bohdan-books.com
          <br />
          Пн.-Пт.: 8.30-17.30 Нд.: 10.00-14.00. Сб.- вихідний
          <br />
          м. Ужгород
          <br />
          Магазин «Кобзарь» <br />
          пл. Корятовича, 1 тел. (0312)33516
          <br />
          м. Харків
          <br />
          «Читариум»
          <br />
          пр-т Правди, 7(угол ул. Чичибабина и Р.Ролана);
          <br />
          тел. 0503029939
          <br />
          «Знак»
          <br />
          пр-т Ленина, 17;
          <br />
          тел. 0504017040, 0675400007, 0675400008
          <br />
          «Книжковий парк»
          <br />
          пл. Розы Люксембург, 4;
          <br />
          тел. 0957181473
          <br />
          «Букс»
          <br />
          ул. Сумская, 51;
          <br />
          тел. (057)7148465
          <br />
          «Книгарня Є» <br />
          вул. Пушкінська, 50/52 <br />
          «Радуга» <br />
          ПГТ Рогань, пр-к Кооперативный, 1 (напротив школы);
          <br />
          тел. 0962802474
          <br />
          Магазин Kidsbook
          <br />
          Харьків. пров. Кравцова 30/1 (28)
          <br />
          Сайт https://kids-book.com.ua/
          <br />
          Тел. +380505001935 (050) 500-19-35
          <br />
          kyivstar (068) 500-19-35
          <br />
          life cell (073) 500-19-35
          <br />
          м. Хмельницький <br />
          Мережа магазинів «Дім книги» <br />
          Магазин №10 <br />
          вул. Грушевського, 50 <br />
          тел. 0382-70-40-04, 067-961-87-46
          <br />
          shop10@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00-19.00, Сб., Нд.: 10.00-17.00
          <br />
          «Книгарня Є» <br />
          вул. Проскурівська, 2 <br />
          м. Чернівці
          <br />
          Мережа магазинів «Дім книги»
          <br />
          Магазин №3 <br />
          вул. Сагайдачного, 6<br />
          тел.0372-52-27-34; 050-618-55-05
          <br />
          shop3@bohdan-books.com
          <br />
          Пн.-Пт.: 10.00-18.00, Сб.: 10.00-15.00, Нд.: вихідний <br />
          Магазин №14 <br />
          вул. Ентузіастів (ринок)
          <br />
          тел.067-350-14-96; 098-804-10-60
          <br />
          booksshop14@rambler.ru
          <br />
          Вт.-Сб.: 10.00-19.00, Нд.: 10.00-16.00, Пн: вихідний <br />
        </p>
        <p id="section4" className={css.paragInWrapBig}>
          Книги Час майстрів на інших ресурсах
          <br />
          Польща ALLEGRO
          <br />
          Barnabus Books https://allegro.pl/uzytkownik/Barnabus_Books
          <br />
          Книги Час майстрів на "ПРОМі" https://chasmaistriv.in.ua/ua/
          <br />
        </p>
      </div>
    </div>
  );
}
