import css from './info.module.css'
import srvd from '../../img/aboutPic.png'
import kuharchuk from '../../img/kuharchuk.jpg'
import tkach from '../../img/tkach.jpg'
import sumonenco from '../../img/sumonenco.jpg'


export default function AboutComp() {





    return(
        <div className={css.aboutWrapAll}>
            <div className={css.smallWrapAbout}>
                <h1 className={css.aboutCompanyH1}><a className={css.aboutCompanyH1} href="#section1">Про видавництво</a>/<a className={css.aboutCompanyH1} href="#section2"> Маніфест видавця</a>/<a className={css.aboutCompanyH1} href="#section3"> Казка про Час майстрів</a>/ <a className={css.aboutCompanyH1} href="#section4">Колектив</a>/<a className={css.aboutCompanyH1} href="#section5"> контакти</a> / <a className={css.aboutCompanyH1} href="#section6">вакансії</a>
</h1>
                
                <p id="section1" className={css.paragInWrapBig}>Про видавництво<br/>

Дуже коротко - ми топимо за еволюцію.	<br/>

Далі довше… <br/>

Видавництво Час Майстрів представлено на ринку дитячої літератури України вже понад 16 років. Але понад 20 років потому ми вже стрясали ринок навчальної літератури своїми книжковими ноу-хау. Дитяча література почалася з народженням першої донечки засновника, Симоненко Олега. <br/>

“Я подився на наявні дитячі книги і побачив, що мені не вистачає де-яких книг для створення середовища росту моєї дитини. Створюючі для свої дітей сподіваюсь на те, що це комусь теж стане в нагоді”, - Симоненко Олег.<br/>

До речі, саме Тих книг ми ще не створили. Все тягнемося до них і вчимося їх створювати. Кожна нова книга наближає нас до нашої мети.<br/>

Видавництво «Час майстрів» прагне створювати інноваційні книги, які допомагають дітям еволюціонувати. Книги, від яких дітям важко відірватися, є для нас необхідною, але недостатньою умовою. Створювати книги, що ґрунтуються на ноу-хау – це те, що ми любимо робити. Працюємо над створенням художньої літератури, енциклопедій. І ми вже почали працювати над навчальною книгою та нон-фікшн. Маємо на меті випуск лише високоякісних книжок, як витворів мистецтва, для читачів більш ніж одного покоління, рідна мова яких не тільки українська.<br/>

Наша місія - допомогти батькам вивести дітей на шлях Майстра.  Майстри використовують розум, щоб наповнити життя добром, світом і святом. <br/>

Для цього ми створюємо книжки, які розгортаєш — смакуєш, читаєш — не можеш відірватися, а закриваєш, збагатившись. Ілон Маск згадує, що читав всі книги, до котрих міг дотягнутися руками.<br/>

Для створення таких книг ми шукаємо Майстрів, що можуть “передати” своє ноу-хау (знаю як) за допомогою книги.<br/>

Ми сімейне видавництво. Працюємо як одна сім’я.<br/>

У 2014 році Фредерік Лалу опублікував книгу «Reinventing organizations», в якій виділив декілька стадій розвитку компанії та присвоїв їм відповідні кольори.<br/>

За нашою оцінкою ми зараз на зеленій стадії розвитку організації. Ми шукаємо спільні цінності, вчимось приймати спільні рішень, розширяти права усіх учасників, спільний контроль і самоконтроль, відкритість, управління за рахунок формування внутрішньої культури праці, вільний графік, віддалений доступ, один за всіх - всі за одного. Плануємо досягти бірюзового рівня - самоорганізація, цілісність, еволюційна мета, холакратія.<br/>

Видавництво "Час майстрів" експерт з дитячої літератури. <br/>
</p>
                <p id="section2" className={css.paragInWrapBig}>
                Маніфест видавця<br/>
Настав час майстрів. Сьогодні роботи (програми) можуть замінити людину всюди, де виробнича діяльність описується бізнес процессами. Що робити людям? Залишається займатись тим, чого не можуть роботи - творити. Кожна людина здатна на творчість. Кожна творчість корисна для Творця, але не кожна творчість корисна для спільноти. Для неї цінна творчість Майстра  Дитина може розвинути в собі майстерність якщо їй цікаво. Треба допогти дитині відкрити в собі цікавість і оволодіти силої. Час руйнувати стереотипи, виходити за межі, створювати небачене. Це завдання для Майстрів.<br/> 

Ілон Маск - майстер. Живе сильно і творить необхідну реальність. Його не замінити роботом. Він може творити не тому, що має гроші, а він має гроші тому що створює необхідне і нове. Книги недостатнє, але необхідне середовище для зростання майстра. У нас є розуміння чому. Про це будемо писати у рамках "Нашої теорії книги" (посилання на блог). Не замінити роботом Малевича, Багряного, Корольова, Сікорського чи Примаченко  - це Майстри.<br/>
									
Ще раз акцентуємо вашу увагу. Ми видаємо тільке те від чого важко відірватися! Цей критерій для нас необхідний, обов'язковий, але не достатній, бо є велика кількість творів, що тебе захоплює, але особисто ми не вбачаємо сенсу витрачати на них наші сили.  Необхідним для на є твір який несе в собі меседж важливий для еволюції, або чи і твір, що міняє стан читач на той, який варто пережити для еволюції. Хоча, по правді сказати, в полеміці можна на кожний твір "натягнути" сенси. Але ми дозволяємо собі за цим питанням не розводити полеміку. <br/>

                </p>
                <p id="section3" className={css.paragInWrapBig}>Казка про час майстрів	<br/>								
Дайте нашим дітям книги — і ви дасте їм крила.<br/>
                                                                                                            	Є. Лепман <br/>
Колись, давним-давно, жив собі леґінь, а дехто і дурнем його прозивав — бо відразу не всяк його розпізнати може. Мав він сил і завзяття чимало, та ще й розуму на трьох стало б. Почав він думку гадати, куди б себе подіти. Придивився, бачить — люди все роблять, аби у них усього більше було, тобто все ушир та й ушир ростуть. А леґінь той волів угору рости, бо висота йому більш до вподоби.<br/>
— От дурник, — казали добрі люди. — Що ти там угорі забув? Від тої висоти ніякого зиску, ушир надійніше буде.<br/>
— Що ж, нам, дурням, не звикати, — відповів на те леґінь.<br/>
Щоб комусь допомогти у зріст додати, потрібно вміти й самому рости.<br/>
— І як його у гору рости? Може, щось з’їсти? — замислився леґінь.<br/>
Одне з'їв — не виріс. Інше з'їв — у ріст не пішов. Урешті-решт «проковтнув» якось він книжку. Одну з'їсть — росте, іншу — не росте, а від третьої тільки меншає. Хитра загадка, та дурень хитріший! Ясне діло — не від усякої книжки в ріст підеш! Навчився розрізняти. І заходився той хлопець книжки «вирощувати», та все вгору й угору намагається виростити. Непросте завдання. Важко самому з ним впоратися. У такій справі МАЙСТРИ потрібні.<br/>
— Настав Час Майстрів, — вирішив леґінь. От і став він Майстрів до своєї справи закликати.<br/>
Отож, люди добрі, той легень знайшов з десяток таких, як він, щоб книжки вирощувати, стали вони товариством  — «Час майстрів» називаються. Самі ростуть, і книжки в них підростають. А коли книжки виростуть, то й вони в ріст йдуть. Усе поволі діється, та поспішати нема куди. За кожним словом — образ великий. Знають вони, що слова самі по собі дуже важливі для щастя людей. Та ось біда — з часом люди позабували ті образи. Тому справжня суть зникла з мови, із книжки та із самого життя. Відомо ж, що люди образами мислять. А думки людські спричиняють або щастя, або нещастя… Є образи які приводять людей до вірних думок, що спричиняють щастя. А де які образи викликають страждання. Книжки мають формувати вірні думки у маленьких і великих людей. Хто таку книгу «ковтне», той у ріст і піде.<br/>
Книжки видавництва «Час майстрів» призначені насамперед для дітей, адже дорослі вже наповнені знаннями. Як кажуть, до повної скрині нічого нового не покладеш. Але коли дорослі дітям читатимуть, може, щось нове через дітей і собі візьмуть.<br/>
Прийшла пора… Настав «Час майстрів».<br/>
</p>
                <p id="section4" className={css.paragInWrapBig}>

                </p>
                <p id="section5" className={css.paragInWrapBig}>
                Контакти<br/>
Друзі, наразі наша телефонія не працює стабільно.<br/>
Будь ласка, пишіть нам через соц. мережі або на електронну пошту для вирішення нагальних питань.<br/>
 
<img src={kuharchuk} className={css.autorWr}/><br/>
Завідуюча інтернет-магазином<br/>
Леся Кухарчук<br/>
+38 067 2315737<br/>
0443375737<br/>
0502820254<br/>
sales@chasmaistriv.com.ua<br/>
 
Запитання у вайбер<br/>
viber://pa?chatURI=chasmaistriv<br/>
 
Запитання у телеграм<br/>
https://teleg.one/chasmaistriv_bot<br/>
<img src={tkach} className={css.autorWr}/><br/>
Зоряна Ткач<br/>
Відділ реалізації (гуртовий продаж)<br/>
+38 097 504 32 94<br/>
zoryana@chasmaistriv.com.ua<br/>
 
<img src={sumonenco} className={css.autorWr}/><br/>
Творець/ в.о.Головного редактора<br/>
Симоненко Олег<br/>
origin@chasmaistriv.com.ua<br/>
 

Директор Григораш Наталя<br/>
+38 067 2315696<br/>
agent@chasmaistriv.com.ua<br/>
 
Відтепер наші видання можна замовити з швидкою доставкою по Європі.<br/>
Офіційній дистриб'ютор ТОВ "Час Майстрів" у ЄС (Польща м. Краків)<br/>
Керівник філії Міла Радченко +48 790820066<br/>
mila@chasmaistriv.com.ua<br/>

                </p>
                <p id="section6" className={css.paragInWrapBig}>
                Вакансії<br/>
 
 Видавництво Час майстрів шукає:<br/>
 критиків дитячої літератури на посаду позаштатного співробітника просимо ваше резюме надсилати на емейл origin@chasmaistriv.com.ua<br/>
 
                </p>
            </div>
        </div>
    )
}
