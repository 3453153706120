import css from './popUp.module.css'




export default function PopUpLibr() {


 

    return(
        <div className={css.librPopWrap}>
<p className={css.mainPopP}>Шановні бібліотекарі, хочемо виразити свою любов до бібліотек. Якщо ви зареєструйтесь на сайті та виберете роль "бібліотекар", то ми надамо вам особисту знижку і знижку для вашої бібліотеки. Наші автори готові до презентацій. Проводимо систематичні розіграші подарунків. Підтримуємо події які ви сплануєте у своїй бібліотеці. Зручне замовлення з використанням сторінки сайту "Прайс-гурт". Швидше і зручніше. Доставляння для бібліотек безплатне.  </p>
        </div>
       
    )
}