import css from './popUp.module.css'




export default function ChouseBook() {


 

    return(
        <div className={css.mainPopWrapChose}>
<p className={css.mainPopP}>Наш сервіс допоможе вам обрати книгу. Ви можете поспілкуватися зі знавцем наших книг  – Лесею. Вона підбере книги під ваш запит. Наше завдання зробити так, щоб ваші діти читали. Для цього важливо підібрати саме ту книгу, яка найкраще підійде конкретній дитині, ми вам у цьому допоможемо. Перед тим як писати Лесі (чат сайту, телеграм, вайбер, імейл), або дзвонити (+380672315737) рекомендуємо скористатися розумним фільтром (кнопка "Усі книги"). Він доволі точний. Так ви значно звузите для себе поле вибору. На сайті працює ще примітивний, але все ж таки штучний інтелект (результат підбору в розділі "вам сподобається" внизу сторінки). Він зауважує ваш попередній вибір книг, використовує ймовірнісний облік масиву даних і розраховує для вас пропозиції. Чим більше ви кладете в кошик і вподобаєте, тим точніший його підбір. Пишіть у коментарях відгук про роботу нашого штучного інтелекту. Це для нас вельми цікаво. Коли ви зробили замовлення, то його статус можете відстежити в особистому кабінеті. Ми бережемо ваш спокій і не закидуємо вас повідомленнями.
</p>
        </div>
       
    )
}