import { useState, useEffect } from "react";
import WithFieldData from "../HOK/withFieldData";
import SmallProductCart from "../standartComponent/productComponent/smallProductCart";
import css from "./main.module.css";

const OneProdActions = ({ products, setCartCounterC, setLikeCounterC }) => {
  const [randomProduct, setRandomProduct] = useState(null);

  useEffect(() => {
    if (products.length > 0) {
      const randomIndex = Math.floor(Math.random() * products.length);
      setRandomProduct(products[randomIndex]);
    }
  }, [products]);

  return (
    <div className={css.oneProdWrapNew}>
      <p className={css.nameOfAcr}>Всі Акції</p>
      {randomProduct && (
        <SmallProductCart
          el={randomProduct}
          setCartCounterC={setCartCounterC}
          setLikeCounterC={setLikeCounterC}
        />
      )}
    </div>
  );
};

export default WithFieldData("sale", "product", "true")(OneProdActions);
