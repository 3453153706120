import css from './popUp.module.css'




export default function PopSp() {


 

    return(
        <div className={css.popSPWrap}>
<p className={css.mainPopP}>Для вас спеціальна ціна, якщо хочете зробити корпоративне замовлення книг на подарунок колегам, організувати СП (сумісну покупку), замовити на групу в садку, або на клас. Всі умови індивідуальні, тому обговорюєте умови з Лесею Кухарчук (+38 067 2315737 sales@chasmaistriv.com.ua). І не забудьте зареєструватись зі статусом "СП". Рекомендуємо вам зручну гуртову форму замовлення книг на сайті та "Прайс-гурт".
</p>
        </div>
       
    )
}